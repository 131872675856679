export const currencyFormatter = (value) => {
  if (value == null) {
    return 'N/A';
  }

  if (value <= 0) {
    return '$0.00';
  }

  return `$${(value / 100).toFixed(2)}`;
};

export const convertCentsToDollars = (value) => {
  if (!value || value < 0) {
    return 0.00;
  }

  return value / 100;
};

export const convertDollarsToCents = (value) => {
  if (!value || value < 0) {
    return 0.00;
  }

  return value * 100;
}

export const fileToBase64 = (file, callback) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      callback(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error converting file to base64:', error);
    };
};
