import React, { useRef, useState } from 'react';
import { Input, message } from 'antd';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';

import LogoWithText from '../../resources/svg/logo-with-text.svg';

const ForgetPassword = (props) => {
  const { authStore, routing } = props;

  const [resetEmail, setResetEmail] = useState();

  const emailInput = useRef();
  const codeInput = useRef();
  const newPasswordInput = useRef();

  const onSendEmail = () => {
    const email = emailInput.current.input.value;

    if (!email) {
      message.error('Please input your email.');
      return;
    }

    authStore.sendResetPasswordEmail(email, (email) => {
      setResetEmail(email);
    });
  }

  const onResetPassword = () => {
    const code = codeInput.current.input.value;
    const newPassword = newPasswordInput.current.input.value;

    if (!code) {
      message.error('Please input the code from your email.');
      return;
    }

    if (!newPassword) {
      message.error('Please input the new password.');
      return;
    }

    authStore.resetPasswordByCode(code, newPassword, () => routing.push('/'));
  }

  return (
    <div className='login'>
      <div className='login__main'>
        <div className='login__logo'>
          <img src={LogoWithText} width='420' />
        </div>

        {
          !!resetEmail ? (
            <div className='login__form'>
              <div className='login__form--label'>Reset password</div>
              <Input className='login__form--input' size='large' value={resetEmail} disabled />
              <Input className='login__form--input' size='large' placeholder='Verification Code' ref={codeInput} />
              <Input className='login__form--input' size='large' type='password' placeholder='New Password' ref={newPasswordInput} />
              <div className='login__form--btn' style={{marginTop: '48px'}} onClick={onResetPassword}>Continue</div>
              <Link className='login__form--link' to='/'>Back to Login</Link>
            </div>
          ) : (
            <div className='login__form'>
              <div className='login__form--label'>Reset password</div>
              <Input className='login__form--input' size='large' placeholder='E-mail' ref={emailInput} />
              <div className='login__form--btn' style={{marginTop: '48px'}} onClick={onSendEmail}>Continue</div>
              <Link className='login__form--link' to='/'>Back to Login</Link>
            </div>
          )
        }
      </div>
    </div>
  );
}

export default inject('authStore', 'routing')(observer(ForgetPassword));
