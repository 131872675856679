import { computed, makeAutoObservable, observable } from "mobx";
import { message } from 'antd';
import Cookies from 'js-cookie';

import rpgRequest from '../lib/rpgRequest.js';

class AuthStore {
  @observable currentUser = null;
  @observable isLoading = true;

  constructor() {
    makeAutoObservable(this);

    this.initUser();
  }

  initUser = () => {
    this.isLoading = true;

    rpgRequest('/user/profile', 'post')
      .then((resp) => {
        this.isLoading = false;

        if (resp.data.code != 200) {
          Cookies.remove('rpg_auth_token');
          try {
            message.error(resp.data.message.map(m => m.msg).join('\n'));
          } catch(e) {
            message.error(resp.data.message);
          }
          return;
        }

        this.currentUser = resp.data.data;
        Cookies.set('rpg_auth_token', this.currentUser.token, { expires: 7 });
      })
      .catch((err) => {
        this.isLoading = false;
        console.error(err);
      });
  }

  login = (username, password) => {
    this.isLoading = true;

    rpgRequest('/user/login', 'post', { username, password })
      .then((resp) => {
        this.isLoading = false;

        if (resp.data.code != 200) {
          try {
            message.error(resp.data.message.map(m => m.msg).join('\n'));
          } catch(e) {
            message.error(resp.data.message);
          }
          return;
        }

        this.currentUser = resp.data.data;
        Cookies.set('rpg_auth_token', this.currentUser.token, { expires: 7 });
      })
      .catch((err) => {
        this.isLoading = false;
        console.error(err);
        message.error('Server error, please try it again.');
      });
  }

  logout = () => {
    this.currentUser = null;
    Cookies.remove('rpg_auth_token');
  }

  resetPasswordByToken = (password) => {
    this.isLoading = true;

    rpgRequest('/user/resetPasswordByToken', 'post', { password })
      .then((resp) => {
        this.isLoading = false;

        if (resp.data.code != 200) {
          try {
            message.error(resp.data.message.map(m => m.msg).join('\n'));
          } catch(e) {
            message.error(resp.data.message);
          }
          return;
        }

        message.success('Success! Please re-login.');
        this.logout();
      })
      .catch((err) => {
        this.isLoading = false;
        console.error(err);
        message.error('Server error, please try it again.');
      });
  }

  sendResetPasswordEmail = (email, callback = () => {}) => {
    rpgRequest('/user/sendResetPasswordEmail', 'post', { email })
      .then((resp) => {
        this.isLoading = false;

        if (resp.data.code != 200) {
          try {
            message.error(resp.data.message.map(m => m.msg).join('\n'));
          } catch(e) {
            message.error(resp.data.message);
          }
          return;
        }

        message.success('Success! Please check your email.');
        callback(email);
      })
      .catch((err) => {
        this.isLoading = false;
        console.error(err);
        message.error('Server error, please try it again.');
      });
  }

  resetPasswordByCode = (code, password, callback = () => {}) => {
    this.isLoading = true;

    rpgRequest('/user/resetPasswordByCode', 'post', { code, password })
      .then((resp) => {
        this.isLoading = false;

        if (resp.data.code != 200) {
          try {
            message.error(resp.data.message.map(m => m.msg).join('\n'));
          } catch(e) {
            message.error(resp.data.message);
          }
          return;
        }

        message.success('Success! Please re-login.');
        callback();
      })
      .catch((err) => {
        this.isLoading = false;
        console.error(err);
        message.error('Server error, please try it again.');
      });
  }
}

export default AuthStore;
