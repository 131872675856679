import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserHistory } from 'history';
import { Provider } from "mobx-react";
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { Router } from 'react-router';

import AuthStore from './stores/AuthStore.js';
import MasterBLStore from './stores/MasterBLStore.js';
import UserStore from './stores/UserStore.js';

import Admin from './containers/Admin';

import './index.less';

const browserHistory = createBrowserHistory();
const routingStore = new RouterStore();

const authStore = new AuthStore();
const userStore = new UserStore();
const masterBLStore = new MasterBLStore(authStore);

const stores = {
  routing: routingStore,
  authStore,
  masterBLStore,
  userStore
}

const history = syncHistoryWithStore(browserHistory, routingStore);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider {...stores}>
    <Router history={history}>
      <Admin />
    </Router>
  </Provider>
);
