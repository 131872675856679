import axios from 'axios';
import Cookies from 'js-cookie';

const rpgRequest = (url, method = 'get', data, headers = {}) => {
  const token = Cookies.get('rpg_auth_token');

  return axios({
    url: __API_ENDPOINT__ + url,
    method,
    withCredential: true,
    headers: {
      'Accept': 'application/json',
      'token': token,
      ...headers
    },
    data
  });
};

export default rpgRequest;
