import React from 'react';
import { inject, observer } from "mobx-react";
import { MenuFoldOutlined, ProductOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { matchPath } from 'react-router';

import './index.less';

import Logo from '../../resources/svg/logo.svg';

const PanelLayout = (props) => {
  const { children, authStore } = props;

  const { currentUser } = authStore;

  const isMatchedSubPath = (path) => {
    const res = matchPath(props.routing.location?.pathname, {
      path,
      exact: false,
      strict: false
    });
    return !!res;
  }

  const dropdownMenu = (
    <Menu>
      <Menu.Item key='logout' onClick={authStore.logout}>Logout</Menu.Item>
    </Menu>
  );

  return (
    <div className='cfs-panel'>
      <div className='cfs-panel__sidebar'>
        <div className='cfs-panel__sidebar--logo'>
          <img src={Logo} height='46' />
        </div>

        <div className='cfs-panel__sidebar--menu'>
          <div className={`cfs-panel__sidebar--primary-menu ${isMatchedSubPath('/master-bl') ? 'current' : ''}`}>
            <ProductOutlined style={{ marginRight: '8px' }} />
            LCL Shipment
          </div>

          <Link className={`cfs-panel__sidebar--secondary-menu ${isMatchedSubPath('/master-bl') ? 'current' : ''}`} to='/master-bl'>
            Master B/L
          </Link>
        </div>

        {
          authStore.currentUser?.type == 'COMPANY_ADMIN' ? (
            <div className='cfs-panel__sidebar--menu'>
              <Link className={`cfs-panel__sidebar--primary-menu ${isMatchedSubPath('/users') ? 'current' : ''}`} to='/users'>
                <UsergroupAddOutlined style={{ marginRight: '8px' }} />
                User Management
              </Link>
            </div>
          ) : null
        }
      </div>

      <div className='cfs-panel__body'>
        <div className='cfs-panel__body-topbar'>
          <div className='cfs-panel__body-topbar--left'>
            <MenuFoldOutlined style={{ marginRight: '16px' }} />
            <span style={{ color: '#44D7B6', marginRight: '12px' }}>LCL Shipment</span>
            <span style={{ color: '#44D7B6', marginRight: '12px' }}>/</span>
            <span>Master B/L</span>
          </div>

          <div className='cfs-panel__body-topbar--right'>
            <Dropdown overlay={ dropdownMenu } placement="bottomLeft" arrow>
              <Avatar shape='square' size='small' style={{ backgroundColor: '#1677ff', cursor: 'pointer' }}>{ (currentUser?.name || currentUser?.email)[0].toUpperCase() }</Avatar>
            </Dropdown>
          </div>
        </div>

        <div className='cfs-panel__body-main'>
          <div className='cfs-panel__body-main--content'>
            { props.children }
          </div>
        </div>
      </div>
    </div>
  );
}

export default inject('authStore', 'routing')(observer(PanelLayout));
