import React, { useState } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import update from 'immutability-helper';
import maxValue from 'lodash.max';

import { Modal, Checkbox, InputNumber, DatePicker } from 'antd';

import { currencyFormatter, convertCentsToDollars, convertDollarsToCents } from '../../utils';
import { calculateHazMat } from '../../utils/feeCalculator.js';

import './index.less';

const FeeForm = (props) => {
  const { initialValues, onSubmit, onCancel, userRole, freeTimeExpires, houseBill } = props;
  if (!initialValues) {
    return null;
  }

  // Basic Fee
  const { documentFee, facilityFee, portDrayageSurcharge, forkliftFee, fuelSurcharge, demurrage, total } = initialValues;

  // Additional Fee
  const { palletFee, labeling, segregation, cartonSegregation, palletizingShrink, hazMat, examInspectionFee, dryRun } = initialValues;

  const [pickupDate, setPickupDate] = useState();
  const [feeInfo, setFeeInfo] = useState({
    documentFee: {
      checked: true,
      value: documentFee,
    },
    facilityFee: {
      checked: true,
      value: facilityFee,
    },
    portDrayageSurcharge: {
      checked: true,
      value: portDrayageSurcharge,
    },
    forkliftFee: {
      checked: true,
      value: forkliftFee
    },
    fuelSurcharge: {
      checked: true,
      value: fuelSurcharge
    },
    demurrage: {
      checked: true,
      value: demurrage
    },
    palletFee: {
      checked: palletFee >= 0,
      value: palletFee,
    },
    labeling: {
      checked: labeling >= 0,
      value: labeling,
    },
    segregation: {
      checked: segregation >= 0,
      value: segregation,
    },
    cartonSegregation: {
      checked: cartonSegregation >= 0,
      value: cartonSegregation,
    },
    palletizingShrink: {
      checked: palletizingShrink >= 0,
      value: palletizingShrink,
    },
    examInspectionFee: {
      checked: examInspectionFee >= 0,
      value: examInspectionFee,
    },
    dryRun: {
      checked: dryRun >= 0,
      value: dryRun,
    },
    hazMat: {
      checked: hazMat >= 0,
      value: hazMat,
    },
  });
  const onPriceChange = (key) => (type) => (value) => {
    setFeeInfo(update(feeInfo, {
      [key]: {
        [type]: {
          $set: value
        }
      }
    }));
  }

  const changeApptTime = (apptMo) => {
    let demurrage = feeInfo.demurrage.value;

    const {freeTimeExpires, volume, weight} = houseBill;
    if (!(freeTimeExpires?.freeTimeExpires > 0)) {
      return;
    }

    const freeTimeExpiresMo = moment(freeTimeExpires.freeTimeExpires).endOf('day');
    if (freeTimeExpiresMo.isSameOrAfter(apptMo)) {
      demurrage = 0;
    } else {
      const diffDay = apptMo.endOf('day').diff(freeTimeExpiresMo, 'day');
      demurrage = maxValue([4000 * diffDay, 500 * (volume || 0) * diffDay, 500 * ((weight || 0) / 800).toFixed(0) * diffDay]);
    }

    setPickupDate(apptMo.endOf('day').valueOf());
    onPriceChange('demurrage')('value')(demurrage);
  }

  const calculateTotal = () => {
    return Object.keys(feeInfo).filter(k => !!feeInfo[k].checked).reduce((acc, k) => {
      return acc + (feeInfo[k].value >= 0 ? feeInfo[k].value : 0);
    }, 0);
  }

  const calculateHazMatFee = () => {
    onPriceChange('hazMat')('value')(calculateHazMat(houseBill?.volume, houseBill?.weight));
  }

  const hasNoAdditionalCharge = () => {
    return !feeInfo.palletFee.checked && !feeInfo.labeling.checked && !feeInfo.segregation.checked && !feeInfo.cartonSegregation.checked && !feeInfo.palletizingShrink.checked && !feeInfo.hazMat.checked && !feeInfo.examInspectionFee.checked && !feeInfo.dryRun.checked;
  }

  const isValidPayingHBL = () => {
    return houseBill?.pcsMan >= 0 && houseBill?.pcsRec >= 0 && houseBill?.pallets >= 0 && houseBill?.weight >= 0 && houseBill.volume >= 0 && houseBill?.freeTimeExpires.freeTimeExpires >= 0;
  }

  const formContent = (
    <div className='fee-form'>
      {
        userRole != 'COMPANY_ADMIN' ? (
          <div className='fee-form__datepicker'>
            <div className='fee-form__datepicker--label'>
              *Please select a pickup date to estimate charges
            </div>
            <DatePicker style={{width: '100%'}} onChange={changeApptTime} allowClear={false} />
          </div>
        ) : null
      }

      <div className='fee-form__row label'>
        <div className='fee-form__row--left'>Basic Charge</div>
        <div className='fee-form__row--right' style={{paddingRight: '18px'}}>Amount</div>
      </div>

      <div className='fee-form__block'>
        <div className='fee-form__row content'>
          <div className='fee-form__row--left'>
            <Checkbox disabled checked style={{marginRight: '12px', display: userRole != 'COMPANY_ADMIN' ? 'none' : 'inline-flex'}} />
            Document Fee
          </div>
          <div className='fee-form__row--right' style={{paddingRight: '18px'}}>{currencyFormatter(documentFee)}</div>
        </div>

        <div className='fee-form__row content'>
          <div className='fee-form__row--left'>
            <Checkbox disabled checked style={{marginRight: '12px', display: userRole != 'COMPANY_ADMIN' ? 'none' : 'inline-flex'}} />
            Facility Fee
          </div>
          <div className='fee-form__row--right' style={{paddingRight: '18px'}}>{currencyFormatter(facilityFee)}</div>
        </div>

        <div className='fee-form__row content'>
          <div className='fee-form__row--left'>
            <Checkbox disabled checked style={{marginRight: '12px', display: userRole != 'COMPANY_ADMIN' ? 'none' : 'inline-flex'}} />
            Port Drayage Surcharge
          </div>
          <div className='fee-form__row--right' style={{paddingRight: '18px'}}>{currencyFormatter(portDrayageSurcharge)}</div>
        </div>

        <div className='fee-form__row content'>
          <div className='fee-form__row--left'>
            <Checkbox disabled checked style={{marginRight: '12px', display: userRole != 'COMPANY_ADMIN' ? 'none' : 'inline-flex'}} />
            Forklift Fee
          </div>
          <div className='fee-form__row--right' style={{paddingRight: '18px'}}>{currencyFormatter(forkliftFee)}</div>
        </div>

        <div className='fee-form__row content'>
          <div className='fee-form__row--left'>
            <Checkbox disabled checked style={{marginRight: '12px', display: userRole != 'COMPANY_ADMIN' ? 'none' : 'inline-flex'}} />
            Fuel Surcharge
          </div>
          <div className='fee-form__row--right' style={{paddingRight: '18px'}}>{currencyFormatter(fuelSurcharge)}</div>
        </div>

        <div className='fee-form__row content' style={{ display: userRole != 'COMPANY_ADMIN' && feeInfo.demurrage.value <= 0 ? 'none' : 'flex' }}>
          <div className='fee-form__row--left'>
            <Checkbox disabled checked style={{marginRight: '12px', display: userRole != 'COMPANY_ADMIN' ? 'none' : 'inline-flex'}} />
            Demurrage
          </div>
          <div className='fee-form__row--right' style={{paddingRight: '18px'}}>{currencyFormatter(feeInfo.demurrage.value)}</div>
        </div>
      </div>

      <div className='fee-form__row label' style={{ display: userRole != 'COMPANY_ADMIN' && hasNoAdditionalCharge() ? 'none' : 'flex' }}>
        <div className='fee-form__row--left'>Additional Charge</div>
        <div className='fee-form__row--right'></div>
      </div>

      <div className='fee-form__block'>
        <div className='fee-form__row content' style={{ display: userRole != 'COMPANY_ADMIN' && !feeInfo.palletFee.checked ? 'none' : 'flex' }}>
          <div className='fee-form__row--left'>
            <Checkbox checked={feeInfo.palletFee.checked} style={{marginRight: '12px', display: userRole != 'COMPANY_ADMIN' ? 'none' : 'inline-flex'}} onChange={(e) => onPriceChange('palletFee')('checked')(e.target.checked)} disabled={userRole != 'COMPANY_ADMIN'} />
            Pallet Fee
          </div>
          <div className='fee-form__row--right'>
            <InputNumber value={convertCentsToDollars(feeInfo.palletFee.value)} disabled={userRole != 'COMPANY_ADMIN' || !feeInfo.palletFee.checked} className='currency-input' prefix='$' step='0.01' onChange={(value) => onPriceChange('palletFee')('value')(value * 100)} />
          </div>
        </div>

        <div className='fee-form__row content' style={{ display: userRole != 'COMPANY_ADMIN' && !feeInfo.labeling.checked ? 'none' : 'flex' }}>
          <div className='fee-form__row--left'>
            <Checkbox checked={feeInfo.labeling.checked} style={{marginRight: '12px', display: userRole != 'COMPANY_ADMIN' ? 'none' : 'inline-flex'}} onChange={(e) => onPriceChange('labeling')('checked')(e.target.checked)} disabled={userRole != 'COMPANY_ADMIN'} />
            Labeling
          </div>
          <div className='fee-form__row--right'>
            <InputNumber value={convertCentsToDollars(feeInfo.labeling.value)} disabled={userRole != 'COMPANY_ADMIN' || !feeInfo.labeling.checked} className='currency-input' prefix='$' step='0.01' onChange={(value) => onPriceChange('labeling')('value')(value * 100)} />
          </div>
        </div>

        <div className='fee-form__row content' style={{ display: userRole != 'COMPANY_ADMIN' && !feeInfo.segregation.checked ? 'none' : 'flex' }}>
          <div className='fee-form__row--left'>
            <Checkbox checked={feeInfo.segregation.checked} style={{marginRight: '12px', display: userRole != 'COMPANY_ADMIN' ? 'none' : 'inline-flex'}} onChange={(e) => onPriceChange('segregation')('checked')(e.target.checked)} disabled={userRole != 'COMPANY_ADMIN'} />
            Segregation
          </div>
          <div className='fee-form__row--right'>
            <InputNumber value={convertCentsToDollars(feeInfo.segregation.value)} disabled={userRole != 'COMPANY_ADMIN' || !feeInfo.segregation.checked} className='currency-input' prefix='$' step='0.01' onChange={(value) => onPriceChange('segregation')('value')(value * 100)} />
          </div>
        </div>

        <div className='fee-form__row content' style={{ display: userRole != 'COMPANY_ADMIN' && !feeInfo.cartonSegregation.checked ? 'none' : 'flex' }}>
          <div className='fee-form__row--left'>
            <Checkbox checked={feeInfo.cartonSegregation.checked} style={{marginRight: '12px', display: userRole != 'COMPANY_ADMIN' ? 'none' : 'inline-flex'}} onChange={(e) => onPriceChange('cartonSegregation')('checked')(e.target.checked)} disabled={userRole != 'COMPANY_ADMIN'} />
            Carton Segregration
          </div>
          <div className='fee-form__row--right'>
            <InputNumber value={convertCentsToDollars(feeInfo.cartonSegregation.value)} disabled={userRole != 'COMPANY_ADMIN' || !feeInfo.cartonSegregation.checked} className='currency-input' prefix='$' step='0.01' onChange={(value) => onPriceChange('cartonSegregation')('value')(value * 100)} />
          </div>
        </div>

        <div className='fee-form__row content' style={{ display: userRole != 'COMPANY_ADMIN' && !feeInfo.palletizingShrink.checked ? 'none' : 'flex' }}>
          <div className='fee-form__row--left'>
            <Checkbox checked={feeInfo.palletizingShrink.checked} style={{marginRight: '12px', display: userRole != 'COMPANY_ADMIN' ? 'none' : 'inline-flex'}} onChange={(e) => onPriceChange('palletizingShrink')('checked')(e.target.checked)} disabled={userRole != 'COMPANY_ADMIN'} />
            Palletizing & Shrink
          </div>
          <div className='fee-form__row--right'>
            <InputNumber value={convertCentsToDollars(feeInfo.palletizingShrink.value)} disabled={userRole != 'COMPANY_ADMIN' || !feeInfo.palletizingShrink.checked} className='currency-input' prefix='$' step='0.01' onChange={(value) => onPriceChange('palletizingShrink')('value')(value * 100)} />
          </div>
        </div>

        <div className='fee-form__row content' style={{ display: userRole != 'COMPANY_ADMIN' && !feeInfo.hazMat.checked ? 'none' : 'flex' }}>
          <div className='fee-form__row--left'>
            <Checkbox disabled checked={feeInfo.hazMat.checked} style={{marginRight: '12px', display: userRole != 'COMPANY_ADMIN' ? 'none' : 'inline-flex'}} />
            HazMat

            {
              userRole == 'COMPANY_ADMIN' && feeInfo.hazMat.checked ? (
                <span className='calc-btn' onClick={calculateHazMatFee}>calculate</span>
              ) : null
            }
          </div>
          <div className='fee-form__row--right'>
            <InputNumber value={convertCentsToDollars(feeInfo.hazMat.value)} disabled={userRole != 'COMPANY_ADMIN' || !feeInfo.hazMat.checked} className='currency-input' prefix='$' step='0.01' onChange={(value) => onPriceChange('hazMat')('value')(value * 100)} />
          </div>
        </div>

        <div className='fee-form__row content' style={{ display: userRole != 'COMPANY_ADMIN' && !feeInfo.examInspectionFee.checked ? 'none' : 'flex' }}>
          <div className='fee-form__row--left'>
            <Checkbox checked={feeInfo.examInspectionFee.checked} style={{marginRight: '12px', display: userRole != 'COMPANY_ADMIN' ? 'none' : 'inline-flex'}} onChange={(e) => onPriceChange('examInspectionFee')('checked')(e.target.checked)} disabled={userRole != 'COMPANY_ADMIN'} />
            Exam Inspection Fee
          </div>
          <div className='fee-form__row--right'>
            <InputNumber value={convertCentsToDollars(feeInfo.examInspectionFee.value)} disabled={userRole != 'COMPANY_ADMIN' || !feeInfo.examInspectionFee.checked} className='currency-input' prefix='$' step='0.01' onChange={(value) => onPriceChange('examInspectionFee')('value')(value * 100)} />
          </div>
        </div>

        <div className='fee-form__row content' style={{ display: userRole != 'COMPANY_ADMIN' && !feeInfo.dryRun.checked ? 'none' : 'flex' }}>
          <div className='fee-form__row--left'>
            <Checkbox checked={feeInfo.dryRun.checked} style={{marginRight: '12px', display: userRole != 'COMPANY_ADMIN' ? 'none' : 'inline-flex'}} onChange={(e) => onPriceChange('dryRun')('checked')(e.target.checked)} disabled={userRole != 'COMPANY_ADMIN'} />
            Dry Run
          </div>
          <div className='fee-form__row--right'>
            <InputNumber value={convertCentsToDollars(feeInfo.dryRun.value)} disabled={userRole != 'COMPANY_ADMIN' || !feeInfo.dryRun.checked} className='currency-input' prefix='$' step='0.01' onChange={(value) => onPriceChange('dryRun')('value')(value * 100)} />
          </div>
        </div>
      </div>

      <div className='fee-form__row label'>
        <div className='fee-form__row--left' style={{textAlign: 'right'}}>Total</div>
        <div className='fee-form__row--right' style={{paddingRight: '18px'}}>{currencyFormatter(calculateTotal())}</div>
      </div>

      {
        userRole != 'COMPANY_ADMIN' ? (
          <div className='fee-form__block btns'>
            <div className='fee-form__block--btn back' onClick={onCancel}>Back</div>
            {
              isValidPayingHBL() ? (
                <div className='fee-form__block--btn pay' onClick={() => onSubmit(initialValues._id, {total: calculateTotal(), detail: feeInfo, pickupDate})}>Pay Now</div>
              ) : (
                <div className='fee-form__block--btn disabled'>Pay Now</div>
              )
            }
          </div>
        ) : null
      }
    </div>
  );

  if (userRole == 'COMPANY_ADMIN') {
    return (
      <Modal
        open={!!initialValues}
        width={650}
        title='CFS Fee'
        okText={'Save'}
        onOk={() => onSubmit(initialValues._id, feeInfo)}
        onCancel={onCancel}
      >
        { formContent }
      </Modal>
    );
  } else {
    return formContent;
  }
}

FeeForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
  houseBill: PropTypes.object,
};

export default FeeForm;
