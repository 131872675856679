import React, { useState } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import update from 'immutability-helper';
import maxValue from 'lodash.max';

import { Modal, Checkbox, InputNumber, DatePicker } from 'antd';

import { currencyFormatter, convertCentsToDollars, convertDollarsToCents } from '../../utils';

import './index.less';

const MBFeeForm = (props) => {
  const { initialValues, onSubmit, onCancel, userRole, freeTimeExpires, houseBill } = props;
  if (!initialValues) {
    return null;
  }

  // Basic Fee
  const { total, warehouseHandingFee, miscellaneousFee1, miscellaneousFee2 } = initialValues;

  const [pickupDate, setPickupDate] = useState();
  const [feeInfo, setFeeInfo] = useState({
    warehouseHandingFee: {
      checked: true,
      value: warehouseHandingFee,
    },
    miscellaneousFee1: {
      checked: miscellaneousFee1 >= 0,
      value: miscellaneousFee1,
    },
    miscellaneousFee2: {
      checked: miscellaneousFee2 >= 0,
      value: miscellaneousFee2,
    },
  });
  const onPriceChange = (key) => (type) => (value) => {
    setFeeInfo(update(feeInfo, {
      [key]: {
        [type]: {
          $set: value
        }
      }
    }));
  }

  const calculateTotal = () => {
    return Object.keys(feeInfo).filter(k => !!feeInfo[k].checked).reduce((acc, k) => {
      return acc + (feeInfo[k].value >= 0 ? feeInfo[k].value : 0);
    }, 0);
  }

  const formContent = (
    <div className='fee-form'>
      <div className='fee-form__row label'>
        <div className='fee-form__row--left'>Basic Charge</div>
        <div className='fee-form__row--right' style={{paddingRight: '18px'}}>Amount</div>
      </div>

      <div className='fee-form__block'>
        {
          userRole != 'COMPANY_ADMIN' && !feeInfo.warehouseHandingFee.checked ? (
            null
          ) : (
            <div className='fee-form__row content'>
              <div className='fee-form__row--left'>
                <Checkbox checked={feeInfo.warehouseHandingFee.checked} style={{marginRight: '12px', display: userRole != 'COMPANY_ADMIN' ? 'none' : 'inline-flex'}} onChange={(e) => onPriceChange('warehouseHandingFee')('checked')(e.target.checked)} disabled={userRole != 'COMPANY_ADMIN'} />
                Warehouse Handling Fee
              </div>
              <div className='fee-form__row--right' style={{paddingRight: '18px'}}>
                <InputNumber value={convertCentsToDollars(feeInfo.warehouseHandingFee.value)} disabled={userRole != 'COMPANY_ADMIN' || !feeInfo.warehouseHandingFee.checked} className='currency-input' prefix='$' step='0.01' onChange={(value) => onPriceChange('warehouseHandingFee')('value')(value * 100)} />
              </div>
            </div>
          )
        }

        {
          userRole != 'COMPANY_ADMIN' && !feeInfo.miscellaneousFee1.checked ? (
            null
          ) : (
            <div className='fee-form__row content'>
              <div className='fee-form__row--left'>
                <Checkbox checked={feeInfo.miscellaneousFee1.checked} style={{marginRight: '12px', display: userRole != 'COMPANY_ADMIN' ? 'none' : 'inline-flex'}} onChange={(e) => onPriceChange('miscellaneousFee1')('checked')(e.target.checked)} disabled={userRole != 'COMPANY_ADMIN'} />
                Miscellaneous Fee 1
              </div>
              <div className='fee-form__row--right' style={{paddingRight: '18px'}}>
                <InputNumber value={convertCentsToDollars(feeInfo.miscellaneousFee1.value)} disabled={userRole != 'COMPANY_ADMIN' || !feeInfo.miscellaneousFee1.checked} className='currency-input' prefix='$' step='0.01' onChange={(value) => onPriceChange('miscellaneousFee1')('value')(value * 100)} />
              </div>
            </div>
          )
        }

        {
          userRole != 'COMPANY_ADMIN' && !feeInfo.miscellaneousFee2.checked ? (
            null
          ) : (
            <div className='fee-form__row content'>
              <div className='fee-form__row--left'>
                <Checkbox checked={feeInfo.miscellaneousFee2.checked} style={{marginRight: '12px', display: userRole != 'COMPANY_ADMIN' ? 'none' : 'inline-flex'}} onChange={(e) => onPriceChange('miscellaneousFee2')('checked')(e.target.checked)} disabled={userRole != 'COMPANY_ADMIN'} />
                Miscellaneous Fee 2
              </div>
              <div className='fee-form__row--right' style={{paddingRight: '18px'}}>
                <InputNumber value={convertCentsToDollars(feeInfo.miscellaneousFee2.value)} disabled={userRole != 'COMPANY_ADMIN' || !feeInfo.miscellaneousFee2.checked} className='currency-input' prefix='$' step='0.01' onChange={(value) => onPriceChange('miscellaneousFee2')('value')(value * 100)} />
              </div>
            </div>
          )
        }

        <div className='fee-form__row content' />
        <div className='fee-form__row content' />
        <div className='fee-form__row content' />
      </div>

      <div className='fee-form__row label'>
        <div className='fee-form__row--left' style={{textAlign: 'right'}}>Total</div>
        <div className='fee-form__row--right' style={{paddingRight: '18px'}}>{currencyFormatter(calculateTotal())}</div>
      </div>

      {
        userRole != 'COMPANY_ADMIN' ? (
          <div className='fee-form__block btns'>
            <div className='fee-form__block--btn back' onClick={onCancel}>Back</div>
              <div className='fee-form__block--btn pay' onClick={() => onSubmit(initialValues._id, {...feeInfo, total: calculateTotal() })}>Pay Now</div>
          </div>
        ) : null
      }
    </div>
  );

  if (userRole == 'COMPANY_ADMIN') {
    return (
      <Modal
        open={!!initialValues}
        width={650}
        title='CFS Fee'
        okText={'Save'}
        onOk={() => onSubmit(initialValues._id, { ...feeInfo, total: calculateTotal() })}
        onCancel={onCancel}
      >
        { formContent }
      </Modal>
    );
  } else {
    return formContent;
  }
}

MBFeeForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
  houseBill: PropTypes.object,
};

export default MBFeeForm;
