import React, { useRef } from 'react';
import { Input, message } from 'antd';
import { inject, observer } from "mobx-react";

import LogoWithText from '../../resources/svg/logo-with-text.svg';

const ResetPasswordByToken = (props) => {
  const { authStore } = props;

  const newPasswordInput = useRef();
  const confirmPasswordInput = useRef();

  const onConfirm = () => {
    const newPassword = newPasswordInput.current.input.value;
    const confirmPassword = confirmPasswordInput.current.input.value;

    if (!newPassword) {
      message.error('Please input your new password.');
      return;
    }

    if (confirmPassword != newPassword) {
      message.error('Please confirm your new password.');
      return;
    }

    authStore.resetPasswordByToken(newPassword)
  }

  return (
    <div className='login'>
      <div className='login__main'>
        <div className='login__logo'>
          <img src={LogoWithText} width='420' />
        </div>

        <div className='login__center-text'>
          <div className='login__center-text--main'>Login Successfully!</div>
          <div className='login__center-text--secondary'>First login, please change your password</div>
        </div>

        <div className='login__form'>
          <Input className='login__form--input' size='large' type='password' placeholder='New Password' ref={newPasswordInput} />
          <Input className='login__form--input' size='large' type='password' placeholder='Confirm Password' ref={confirmPasswordInput} />
          <div className='login__form--btn' onClick={onConfirm}>Change Password</div>
        </div>
      </div>
    </div>
  );
}

export default inject('authStore')(observer(ResetPasswordByToken));
