import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Table, Form, Input, Select, DatePicker, Radio, InputNumber } from 'antd';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';

import HouseBLForm from '../../components/HouseBLForm';
import FeeForm from '../../components/FeeForm';
import MBFeeForm from '../../components/FeeForm/mbForm';

import { currencyFormatter, convertCentsToDollars } from '../../utils';

import './index.less';

const CFSMasterBLDetail = (props) => {
  const { currentUser } = props.authStore;
  const { currentMasterBL, fetchMasterBLDetail, clearCurrentMasterBL, updateMasterBL, addHouseBL, updateHouseBL, removeHouseBL, updateHouseBLFee, updateMasterBLFee } = props.masterBLStore;

  const { id } = useParams();
  useEffect(() => {
    if (!currentMasterBL) {
      fetchMasterBLDetail(id, initMasterBLDetail);
    }

    return () => clearCurrentMasterBL();
  }, []);

  const [cfsCharingMode, setCFSChargingMode] = useState('HB');
  const [masterBLInitialValues, setMasterBLInitialValues] = useState({});
  const initMasterBLDetail = (masterBL) => {
    setCFSChargingMode(masterBL.CFSChargingModel);
    setMasterBLInitialValues(() => {
      const values = {
        ...masterBL,
        vesselDeparture: masterBL.vesselDeparture > 0 ? moment(masterBL.vesselDeparture) : undefined,
        vesselETA: masterBL.vesselETA > 0 ? moment(masterBL.vesselETA) : undefined,
        vesselATA: masterBL.vesselATA > 0 ? moment(masterBL.vesselATA) : undefined,
        dischargeDate: masterBL.dischargeDate > 0 ? moment(masterBL.dischargeDate) : undefined,
        availableAtPier: masterBL.availableAtPier > 0 ? moment(masterBL.availableAtPier) : undefined,
        pierLFD: masterBL.pierLFD > 0 ? moment(masterBL.pierLFD) : undefined,
        apptTime: masterBL.apptTime > 0 ? moment(masterBL.apptTime) : undefined,
        driverDispatch: masterBL.driverDispatch > 0 ? moment(masterBL.driverDispatch) : undefined,
        outgatedFromPier: masterBL.outgatedFromPier > 0 ? moment(masterBL.outgatedFromPier) : undefined,
        arrivedAtRPG: masterBL.arrivedAtRPG > 0 ? moment(masterBL.arrivedAtRPG) : undefined,
        availableAtRPG: masterBL.availableAtRPG > 0 ? moment(masterBL.availableAtRPG) : undefined,
        freeTimeExpires: masterBL.freeTimeExpires?.freeTimeExpires > 0 ? moment(masterBL.freeTimeExpires.freeTimeExpires) : undefined,
        emptyReturnDate: masterBL.emptyReturnDate > 0 ? moment(masterBL.emptyReturnDate) : undefined,
        CFSChargingModel: masterBL.CFSChargingModel || 'HB',
      };
      setTimeout(() => {
        masterBLForm.resetFields(Object.keys(values));
      });
      return values;
    });
  };
  const [masterBLForm] = Form.useForm();

  const onUpdateMasterBill = () => {
    masterBLForm.validateFields()
      .then((data) => {
        const formattedData = {
          ...data,
          _id: id,
          vesselDeparture: data.vesselDeparture ? data.vesselDeparture.endOf('day').valueOf() : -1,
          vesselETA: data.vesselETA ? data.vesselETA.endOf('day').valueOf() : -1,
          vesselATA: data.vesselATA ? data.vesselATA.endOf('day').valueOf() : -1,
          dischargeDate: data.dischargeDate ? data.dischargeDate.endOf('day').valueOf() : -1,
          availableAtPier: data.availableAtPier ? data.availableAtPier.endOf('day').valueOf() : -1,
          pierLFD: data.pierLFD ? data.pierLFD.endOf('day').valueOf() : -1,
          apptTime: data.apptTime ? data.apptTime.endOf('day').valueOf() : -1,
          driverDispatch: data.driverDispatch ? data.driverDispatch.endOf('day').valueOf() : -1,
          outgatedFromPier: data.outgatedFromPier ? data.outgatedFromPier.endOf('day').valueOf() : -1,
          arrivedAtRPG: data.arrivedAtRPG ? data.arrivedAtRPG.endOf('day').valueOf() : -1,
          availableAtRPG: data.availableAtRPG ? data.availableAtRPG.endOf('day').valueOf() : -1,
          freeTimeExpires: data.freeTimeExpires ? data.freeTimeExpires.endOf('day').valueOf() : -1,
          emptyReturnDate: data.emptyReturnDate ? data.emptyReturnDate.endOf('day').valueOf() : -1,
        };
        updateMasterBL(formattedData);
      })
      .catch((err) => {
        console.info(err);
      });
  }

  const checkMasterBillValueChange = ([info]) => {
    const { name: [field] } = info;
    if (field == 'CFSChargingModel') {
      setCFSChargingMode(info.value);
    }
  }

  const [houseBLInitialValues, setHouseBLInitialValues] = useState(undefined);
  const [houseBLForm] = Form.useForm();

  const decodeHouseBillData = (data) => {
    return {
      ...data,
      freeTimeExpires: data.freeTimeExpires?.freeTimeExpires > 0 ? moment(data.freeTimeExpires.freeTimeExpires) : undefined,
      shipDate: data.shipDate > 0 ? moment(data.shipDate) : undefined,
      eta: data.eta > 0 ? moment(data.eta) : undefined,
      ata: data.ata > 0 ? moment(data.ata) : undefined,
      appt: data.appt > 0 ? moment(data.appt) : undefined,
    };
  };

  const encodeHouseBillData = (data) => {
    return {
      ...data,
      freeTimeExpires: data.freeTimeExpires ? data.freeTimeExpires.endOf('day').valueOf() : -1,
      shipDate: data.shipDate ? data.shipDate.endOf('day').valueOf() : -1,
      eta: data.eta ? data.eta.endOf('day').valueOf() : -1,
      ata: data.ata ? data.ata.endOf('day').valueOf() : -1,
      appt: data.appt ? data.appt.endOf('day').valueOf() : -1,
    };
  };

  const onCreateHouseBill = () => {
    houseBLForm.validateFields()
      .then((data) => {
        addHouseBL({
          ...encodeHouseBillData(data),
          masterBill: id
        }, id, () => setHouseBLInitialValues());
      })
      .catch((err) => {
        console.info(err);
      });
  }

  const onUpdateHouseBill = () => {
    houseBLForm.validateFields()
      .then((data) => {
        updateHouseBL({
          ...encodeHouseBillData(data),
          _id: houseBLInitialValues._id
        }, id, houseBLInitialValues.CFSFee._id, () => setHouseBLInitialValues());
      })
      .catch((err) => {
        console.info(err);
      });
  }

  const [feeInitialValues, setFeeInitialValues] = useState(undefined);
  const updateFeeInfo = (_id, data) => {
    const serializedData = Object.keys(data).reduce((acc, k) => {
      if (!!data[k].checked) {
        acc[k] = data[k].value >= 0 ? data[k].value : 0;
      } else {
        acc[k] = -1;
      }
      return acc;
    }, {});
    updateHouseBLFee({...serializedData, _id}, () => setFeeInitialValues())
  }

  const [mbFeeInitialValues, setMBFeeInitialValues] = useState(undefined);
  const updateMBFeeInfo = (_id, data) => {
    const serializedData = Object.keys(data).reduce((acc, k) => {
      if (!!data[k].checked) {
        acc[k] = data[k].value >= 0 ? data[k].value : 0;
      } else {
        acc[k] = -1;
      }
      return acc;
    }, {});
    updateMasterBLFee({...serializedData, _id}, () => setMBFeeInitialValues())
  }

  const columns = [
    {
      title: 'House B/L',
      key: 'housebl',
      dataIndex: 'houseBill',
      align: 'center',
    },
    {
      title: 'AMS B/L No',
      key: 'amsno',
      dataIndex: 'amsNumber',
      align: 'center',
    },
    {
      title: 'Available',
      key: 'available',
      align: 'center',
      render: (record) => {
        return (!record.cargoHold || record.cargoHold == 'No') && record.customsRelease == 'Released' && record.freightCharge == 'Released' && record.CFSPayment == 'Paid' ? 'Yes' : 'No';
      }
    },
    {
      title: 'Customs',
      key: 'customsRelease',
      dataIndex: 'customsRelease',
      align: 'center',
    },
    {
      title: 'Freight',
      key: 'freightcharge',
      dataIndex: 'freightCharge',
      align: 'center',
    },
    {
      title: 'Pcs',
      key: 'pcsRec',
      dataIndex: 'pcsRec',
      align: 'center',
    },
    {
      title: 'Pkg',
      key: 'pkgType',
      dataIndex: 'pkgType',
      align: 'center',
    },
    {
      title: 'LBS',
      key: 'lbs',
      dataIndex: 'weight',
      align: 'center',
    },
    {
      title: 'CBM',
      key: 'cbm',
      dataIndex: 'volume',
      align: 'center',
    },
    {
      title: 'Description',
      key: 'description',
      dataIndex: 'description',
      align: 'center',
    },
    {
      title: 'Head Load',
      key: 'headLoad',
      dataIndex: 'headLoad',
      align: 'center',
    },
    {
      title: 'Mks Hold',
      key: 'marksHold',
      dataIndex: 'marksHold',
      align: 'center',
    },
    {
      title: 'Hazmat',
      key: 'hazmat',
      dataIndex: 'hazmat',
      align: 'center',
    },
    {
      title: 'CFS Fee',
      key: 'cfsfee',
      align: 'center',
      dataIndex: 'CFSFee',
      render: (feeInfo) => {
        if (cfsCharingMode == 'MB') {
          return '--';
        }

        const totalCents = feeInfo?.total;
        if (currentUser.type == 'COMPANY_ADMIN') {
          return <a onClick={ () => setFeeInitialValues(feeInfo) }>{currencyFormatter(totalCents)}</a>;
        } else {
          return currencyFormatter(totalCents);
        }
      }
    },
    {
      title: 'Payment Status',
      key: 'CFSPayment',
      dataIndex: 'CFSPayment',
      align: 'center',
      render: (content) => {
        if (cfsCharingMode == 'MB') {
          return currentMasterBL.CFSPayment;
        }

        return content;
      }
    },
    {
      title: 'Appt',
      key: 'appt',
      dataIndex: 'appt',
      align: 'center',
      render: (content) => {
        return content ? content.format('MM/DD/YYYY') : '-';
      }
    },
    {
      title: 'Ship Date',
      key: 'shipDate',
      dataIndex: 'shipDate',
      align: 'center',
      render: (content) => {
        return content ? content.format('MM/DD/YYYY') : '-';
      }
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      render: (record) => {
        return (
          <div>
            <a onClick={() => setHouseBLInitialValues(record)}>Edit</a> | <a onClick={() => removeHouseBL(record._id, id)}>Del</a>
          </div>
        );
      }
    }
  ];

  return (
    <div className='cfs-master-bl-detail'>
      <div className='cfs-master-bl-detail__detail'>
        <div className='cfs-master-bl-detail__topbar'>
          <div className='cfs-master-bl-detail__topbar--left'>
            <label>Details</label>
            <span>{ currentMasterBL?.status }</span>
          </div>

          <div className='cfs-master-bl-detail__topbar--right'>
            <div className='cfs-master-bl-detail__btn' onClick={onUpdateMasterBill}>Save</div>
          </div>
        </div>

        <Form
          className='cfs-master-bl-detail__detail--info'
          form={masterBLForm}
          layout='vertical'
          initialValues={masterBLInitialValues}
          onFieldsChange={checkMasterBillValueChange}
        >
          <Form.Item
            className='cfs-master-bl-detail__detail--info-block'
            label='Master B/L No'
            name='masterBill'
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            className='cfs-master-bl-detail__detail--info-block'
            label='Container No'
            name='containerNumber'
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            className='cfs-master-bl-detail__detail--info-block'
            label='Status'
            name='status'
          >
            <Select disabled={currentUser.type != 'COMPANY_ADMIN'}>
              <Select.Option value='Pending'>Pending</Select.Option>
              <Select.Option value='Processing'>Processing</Select.Option>
              <Select.Option value='Rejected'>Rejected</Select.Option>
              <Select.Option value='PickedUp'>PickedUp</Select.Option>
              <Select.Option value='Completed'>Completed</Select.Option>
              <Select.Option style={{display: 'none'}} value='Cancelled'>Cancelled</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            className='cfs-master-bl-detail__detail--info-block'
            label='Vessel Name'
            name='vesselName'
          >
            <Input />
          </Form.Item>

          <Form.Item
            className='cfs-master-bl-detail__detail--info-block'
            label='Vessel Departure'
            name='vesselDeparture'
          >
            <DatePicker style={{ width: '100%' }} format='MM/DD/YYYY' />
          </Form.Item>

          <Form.Item
            className='cfs-master-bl-detail__detail--info-block'
            label='Vessel ETA'
            name='vesselETA'
          >
            <DatePicker style={{ width: '100%' }} format='MM/DD/YYYY' />
          </Form.Item>

          <Form.Item
            className='cfs-master-bl-detail__detail--info-block'
            label='Vessel ATA'
            name='vesselATA'
          >
            <DatePicker disabled={currentUser.type != 'COMPANY_ADMIN'} style={{ width: '100%' }} format='MM/DD/YYYY' />
          </Form.Item>

          <Form.Item
            className='cfs-master-bl-detail__detail--info-block'
            label='Discharge Date'
            name='dischargeDate'
          >
            <DatePicker disabled={currentUser.type != 'COMPANY_ADMIN'} style={{ width: '100%' }} format='MM/DD/YYYY' />
          </Form.Item>

          <Form.Item
            className='cfs-master-bl-detail__detail--info-block'
            label='Available at Terminal'
            name='availableAtPier'
          >
            <DatePicker disabled={currentUser.type != 'COMPANY_ADMIN'} style={{ width: '100%' }} format='MM/DD/YYYY' />
          </Form.Item>

          <Form.Item
            className='cfs-master-bl-detail__detail--info-block'
            label='Terminal Last Free Day'
            name='pierLFD'
          >
            <DatePicker disabled={currentUser.type != 'COMPANY_ADMIN'} style={{ width: '100%' }} format='MM/DD/YYYY' />
          </Form.Item>

          <Form.Item
            className='cfs-master-bl-detail__detail--info-block'
            label='Appt Time'
            name='apptTime'
          >
            <DatePicker showTime={{ format: 'HH:mm', minuteStep: 15 }} disabled={currentUser.type != 'COMPANY_ADMIN'} style={{ width: '100%' }} format='MM/DD/YYYY HH:mm' />
          </Form.Item>

          <Form.Item
            className='cfs-master-bl-detail__detail--info-block'
            label='Driver Dispatch'
            name='driverDispatch'
          >
            <DatePicker disabled={currentUser.type != 'COMPANY_ADMIN'} style={{ width: '100%' }} format='MM/DD/YYYY' />
          </Form.Item>

          <Form.Item
            className='cfs-master-bl-detail__detail--info-block'
            label='Outgated from Terminal'
            name='outgatedFromPier'
          >
            <DatePicker disabled={currentUser.type != 'COMPANY_ADMIN'} style={{ width: '100%' }} format='MM/DD/YYYY' />
          </Form.Item>

          <Form.Item
            className='cfs-master-bl-detail__detail--info-block'
            label='Arrived at RPG'
            name='arrivedAtRPG'
          >
            <DatePicker disabled={currentUser.type != 'COMPANY_ADMIN'} style={{ width: '100%' }} format='MM/DD/YYYY' />
          </Form.Item>

          <Form.Item
            className='cfs-master-bl-detail__detail--info-block'
            label='Available at RPG'
            name='availableAtRPG'
          >
            <DatePicker disabled={currentUser.type != 'COMPANY_ADMIN'} style={{ width: '100%' }} format='MM/DD/YYYY' />
          </Form.Item>

          <Form.Item
            className='cfs-master-bl-detail__detail--info-block'
            label='Last Free Day'
            name='freeTimeExpires'
          >
            <DatePicker disabled={currentUser.type != 'COMPANY_ADMIN'} style={{ width: '100%' }} format='MM/DD/YYYY' />
          </Form.Item>

          <Form.Item
            className='cfs-master-bl-detail__detail--info-block'
            label='Empty Return Date'
            name='emptyReturnDate'
          >
            <DatePicker disabled={currentUser.type != 'COMPANY_ADMIN'} style={{ width: '100%' }} format='MM/DD/YYYY' />
          </Form.Item>

          <Form.Item
            className='cfs-master-bl-detail__detail--info-block'
            label='Customer Reference'
            name='customerReference'
          >
            <Input />
          </Form.Item>

          <Form.Item
            className='cfs-master-bl-detail__detail--info-block'
            label='RPG Reference Number'
            name='RPGReferenceNumber'
          >
            <Input disabled={currentUser.type != 'COMPANY_ADMIN'} />
          </Form.Item>

          <Form.Item
            className='cfs-master-bl-detail__detail--info-block'
            label='CFS Charging Model'
            name='CFSChargingModel'
          >
            <Radio.Group
              className='cfs-radio cfs-radio__options-2'
              disabled={currentUser.type != 'COMPANY_ADMIN'}
              options={[
                { label: 'By House B/L', value: 'HB' },
                { label: 'By Master B/L', value: 'MB' },
              ]}
              optionType='button'
              buttonStyle='solid'
            />
          </Form.Item>

          {
            cfsCharingMode == 'MB' ? (
              <Form.Item
                className='cfs-master-bl-detail__detail--info-block'
                label='Total CFS Fee'
              >
                <Input style={{ color: '#1890ff', fontWeight: 'bolder' }} onFocus={() => setMBFeeInitialValues(currentMasterBL.CFSFee)} value={ currencyFormatter(currentMasterBL?.CFSFee?.total) } />
              </Form.Item>
            ) : null
          }

          {
            cfsCharingMode == 'MB' ? (
              <Form.Item
                className='cfs-master-bl-detail__detail--info-block'
                label='Payment Status'
                name='CFSPayment'
              >
                <Radio.Group
                  className='cfs-radio cfs-radio__options-2'
                  disabled={currentUser.type != 'COMPANY_ADMIN'}
                  options={[
                    { label: 'Pending', value: 'Pending' },
                    { label: 'Paid', value: 'Paid' },
                  ]}
                  optionType='button'
                  buttonStyle='solid'
                />
              </Form.Item>
            ) : null
          }
        </Form>
      </div>

      <div className='cfs-master-bl-detail__list'>
        <div className='cfs-master-bl-detail__topbar'>
          <div className='cfs-master-bl-detail__topbar--left'>
            <label>House B/L List</label>
          </div>

          <div className='cfs-master-bl-detail__topbar--right'>
            <div className='cfs-master-bl-detail__btn' onClick={() => setHouseBLInitialValues({})}>Add More</div>
          </div>
        </div>

        <div className='cfs-master-bl-detail__list--content'>
          <Table
            rowKey='_id'
            columns={columns}
            dataSource={currentMasterBL?.houseBillList.map(h => decodeHouseBillData(h))}
          />
        </div>
      </div>

      <HouseBLForm
        form={houseBLForm}
        initialValues={houseBLInitialValues}
        userRole={currentUser.type}
        onSubmit={houseBLInitialValues?._id ? onUpdateHouseBill : onCreateHouseBill}
        onCancel={() => setHouseBLInitialValues()}
        mlStatus={ cfsCharingMode == 'MB' ? currentMasterBL.CFSPayment : null }
      />

      <FeeForm
        initialValues={feeInitialValues}
        userRole={currentUser.type}
        onSubmit={updateFeeInfo}
        onCancel={() => setFeeInitialValues()}
      />

      <MBFeeForm
        initialValues={mbFeeInitialValues}
        userRole={currentUser.type}
        onSubmit={updateMBFeeInfo}
        onCancel={() => setMBFeeInitialValues()}
      />
    </div>
  );
}

export default inject('masterBLStore', 'authStore')(observer(CFSMasterBLDetail));
