import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import moment from 'moment';

import { Modal, Form, Input, Select, DatePicker } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';

import UserForm from '../../components/UserForm';

const MasterBLForm = (props) => {
  const { open, onSubmit, form, findUser, onCancel, userRole, addUser, fetchUsers } = props;
  const [validUsers, setValidUsers] = useState([]);
  const [newUser, setNewUser] = useState(undefined);

  useEffect(() => {
    setTimeout(() => {
      form.resetFields();
    });
  }, [open]);

  useEffect(() => {
    fetchUsers((usersData) => {
      setValidUsers(usersData.list);
    });
  }, []);

  const queryUser = useCallback(
    debounce((email) => findUser(email, setValidUsers)
    , 500)
  , []);

  const onUserFormSubmit = (data) => {
    addUser(data, (userData) => () => {
      setNewUser(undefined);
      setValidUsers([...validUsers, userData.data]);
      setTimeout(() => {
        form.setFieldValue('user', userData.data._id);
      });
    });
  }

  return (
    <Modal
      title='Add Master B/L'
      okText='Confirm'
      open={open}
      onCancel={onCancel}
      onOk={onSubmit}
    >
      <Form
        form={form}
        layout='vertical'
      >
        <Form.Item
          label='Master B/L'
          name='masterBill'
          rules={[{ required: true, message: 'Master bill is required.' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Container Number'
          name='containerNumber'
          rules={[{ required: true, message: 'Container number is required.' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='House B/L'
          name='houseBill'
        >
          <Input.TextArea rows={6} placeholder='One house bill per line.' />
        </Form.Item>

        {
          userRole == 'COMPANY_ADMIN' ? (
            <Form.Item
              label='Customer Email'
              name='user'
              rules={[{ required: true, message: 'Customer email is required.' }]}
            >
              <Select
                showSearch
                onSearch={queryUser}
                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <div style={{height: '32px', lineHeight: '32px', textAlign: 'center', color: '#2C6CE4', fontSize: '14px', cursor: 'pointer', borderTop: '1px solid #EAE5E5'}} onClick={() => setNewUser({})}>
                      <PlusCircleOutlined style={{marginRight: '8px'}} />
                      Add a new customer
                    </div>
                  </>
                )}
                options={validUsers.map((u) => ({
                  value: u._id,
                  label: u.email,
                }))}
              />
            </Form.Item>
          ) : null
        }
      </Form>

      <UserForm
        editUser={newUser}
        onSubmit={onUserFormSubmit}
        onCancel={() => setNewUser(undefined)}
      />
    </Modal>
  );
}

MasterBLForm.propTypes = {
  userRole: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  findUser: PropTypes.func,
  form: PropTypes.object.isRequired,
  addUser: PropTypes.func.isRequired,
  fetchUsers: PropTypes.func.isRequired,
};

export default MasterBLForm;
