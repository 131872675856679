import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Modal, Form, Input, Radio, Select, DatePicker } from 'antd';

import './index.less';

const HouseBLForm = (props) => {
  const { initialValues, onSubmit, form, onCancel, userRole, mlStatus } = props;

  useEffect(() => {
    setTimeout(() => {
      form.resetFields();
    });
  }, [initialValues]);

  return (
    <Modal
      title={`${initialValues?._id ? `Edit ${initialValues.houseBill}` : 'Add House B/L'}`}
      width={960}
      okText='Confirm'
      open={!!initialValues}
      onCancel={onCancel}
      onOk={() => onSubmit(initialValues?._id)}
    >
      <Form
        className='housebl-form'
        form={form}
        layout='vertical'
        initialValues={ mlStatus ? {...initialValues, CFSPayment: mlStatus} : initialValues }
      >
        <div className='housebl-form__block'>
          <div className='housebl-form__block--label'>
            Cargo Details
          </div>

          <div className='housebl-form__block--content'>
            <Form.Item
              className='housebl-form__block--content-item'
              label='House B/L'
              name='houseBill'
              rules={[{ required: true, message: 'House bill is required.' }]}
            >
              <Input disabled={!!initialValues?._id} />
            </Form.Item>

            <Form.Item
              className='housebl-form__block--content-item'
              label='AMS B/L'
              name='amsNumber'
            >
              <Input />
            </Form.Item>

            <Form.Item
              className='housebl-form__block--content-item'
              label='Hazmat'
              name='hazmat'
              rules={[{ required: true, message: 'Hazmat option is required.' }]}
            >
              <Select>
                <Select.Option value='No'>No</Select.Option>
                <Select.Option value='Yes'>Yes</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              className='housebl-form__block--content-item'
              label='Description'
              name='description'
            >
              <Input />
            </Form.Item>

            <Form.Item
              className='housebl-form__block--content-item'
              label='Total Pieces(Manifested)'
              name='pcsMan'
            >
              <Input type='number' />
            </Form.Item>

            <Form.Item
              className='housebl-form__block--content-item'
              label='Total Pieces(Received)'
              name='pcsRec'
            >
              <Input type='number' />
            </Form.Item>

            <Form.Item
              className='housebl-form__block--content-item'
              label='Package Type'
              name='pkgType'
            >
              <Select>
                <Select.Option value='Carton'>Carton</Select.Option>
                <Select.Option value='Piece'>Piece</Select.Option>
                <Select.Option value='Pallet'>Pallet</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              className='housebl-form__block--content-item'
              label='Pallets'
              name='pallets'
            >
              <Input type='number' />
            </Form.Item>

            <Form.Item
              className='housebl-form__block--content-item'
              label='Weight (LBS)'
              name='weight'
            >
              <Input type='number' />
            </Form.Item>

            <Form.Item
              className='housebl-form__block--content-item'
              label='Volume (CBM)'
              name='volume'
            >
              <Input type='number' />
            </Form.Item>

            <Form.Item
              className='housebl-form__block--content-item'
              label='Head Load'
              name='headLoad'
            >
              <Select>
                <Select.Option value='No'>No</Select.Option>
                <Select.Option value='Yes'>Yes</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              className='housebl-form__block--content-item'
              label='Last Free Day'
              name='freeTimeExpires'
            >
              <DatePicker disabled={userRole != 'COMPANY_ADMIN'} style={{width: '100%'}} format='MM/DD/YYYY' />
            </Form.Item>
          </div>
        </div>

        <div className='housebl-form__block'>
          <div className='housebl-form__block--label'>
            Pick Up Requirements
          </div>

          <div className='housebl-form__block--content'>
            <Form.Item
              className='housebl-form__block--content-item'
              label='Customs Release'
              name='customsRelease'
            >
              <Select>
                <Select.Option value='No'>No</Select.Option>
                <Select.Option value='Released'>Released</Select.Option>
                <Select.Option value='EXAM'>EXAM</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              className='housebl-form__block--content-item'
              label='Freight Release'
              name='freightCharge'
            >
              <Select>
                <Select.Option value='No'>No</Select.Option>
                <Select.Option value='Released'>Released</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              className='housebl-form__block--content-item'
              label='Payment Status'
              name='CFSPayment'
            >
              <Select disabled={userRole != 'COMPANY_ADMIN' || !!mlStatus}>
                <Select.Option value='Pending'>Pending</Select.Option>
                <Select.Option value='Paid'>Paid</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              className='housebl-form__block--content-item'
              label='Pick Up Number'
              name='pickUpNumber'
            >
              <Input disabled={userRole != 'COMPANY_ADMIN'} />
            </Form.Item>

            <Form.Item
              className='housebl-form__block--content-item'
              label='Delivery Order'
              name='deliveryOrder'
            >
              <Select disabled={userRole != 'COMPANY_ADMIN'}>
                <Select.Option value='Required'>Required</Select.Option>
                <Select.Option value='Received'>Received</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              className='housebl-form__block--content-item'
              label='Exchange Pallets'
              name='exchangePallets'
            >
              <Input disabled={userRole != 'COMPANY_ADMIN'} type='number' />
            </Form.Item>

            <Form.Item
              className='housebl-form__block--content-item'
              label='Cargo On Hold'
              name='cargoHold'
            >
              <Select disabled={userRole != 'COMPANY_ADMIN'}>
                <Select.Option value='No'>No</Select.Option>
                <Select.Option value='Yes'>Yes</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              className='housebl-form__block--content-item'
              label='Hold Reason'
              name='marksHold'
            >
              <Input disabled={userRole != 'COMPANY_ADMIN'} />
            </Form.Item>
          </div>
        </div>

        <div className='housebl-form__block'>
          <div className='housebl-form__block--label'>
            Shipping Information
          </div>

          <div className='housebl-form__block--content'>
            <Form.Item
              className='housebl-form__block--content-item'
              label='Pick Up Agent'
              name='pickUpAgent'
            >
              <Input disabled={userRole != 'COMPANY_ADMIN'} />
            </Form.Item>

            <Form.Item
              className='housebl-form__block--content-item'
              label='Ship Date'
              name='shipDate'
            >
              <DatePicker disabled={userRole != 'COMPANY_ADMIN'} style={{width: '100%'}} format='MM/DD/YYYY' />
            </Form.Item>

            <Form.Item
              className='housebl-form__block--content-item'
              label='ETA'
              name='eta'
            >
              <DatePicker disabled={userRole != 'COMPANY_ADMIN'} style={{width: '100%'}} format='MM/DD/YYYY' />
            </Form.Item>

            <Form.Item
              className='housebl-form__block--content-item'
              label='ATA'
              name='ata'
            >
              <DatePicker disabled={userRole != 'COMPANY_ADMIN'} style={{width: '100%'}} format='MM/DD/YYYY' />
            </Form.Item>

            <Form.Item
              className='housebl-form__block--content-item'
              label='Pro Number'
              name='proNumber'
            >
              <Input disabled={userRole != 'COMPANY_ADMIN'} />
            </Form.Item>

            <Form.Item
              className='housebl-form__block--content-item'
              label='Delivery Pro Number'
              name='deliveryProNumber'
            >
              <Input disabled={userRole != 'COMPANY_ADMIN'} />
            </Form.Item>

            <Form.Item
              className='housebl-form__block--content-item'
              label='Destination'
              name='destination'
            >
              <Input disabled={userRole != 'COMPANY_ADMIN'} />
            </Form.Item>

            <Form.Item
              className='housebl-form__block--content-item'
              label='IT Number'
              name='ITNumber'
            >
              <Input disabled={userRole != 'COMPANY_ADMIN'} />
            </Form.Item>

            <Form.Item
              className='housebl-form__block--content-item'
              label='Load Number'
              name='loadNumber'
            >
              <Input disabled={userRole != 'COMPANY_ADMIN'} />
            </Form.Item>

            <Form.Item
              className='housebl-form__block--content-item'
              label='Trailer Number'
              name='trailerNumber'
            >
              <Input disabled={userRole != 'COMPANY_ADMIN'} />
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  );
}

HouseBLForm.propTypes = {
  userRole: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  mlStatus: PropTypes.string,
};

export default HouseBLForm;
