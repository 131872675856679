import React, { useRef } from 'react';
import { Input, message } from 'antd';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';

import LogoWithText from '../../resources/svg/logo-with-text.svg';

import './index.less';

const Login = (props) => {
  const { authStore } = props;

  const usernameInput = useRef();
  const passwordInput = useRef();

  const onLogin = () => {
    const username = usernameInput.current.input.value;
    const password = passwordInput.current.input.value;

    if (!username) {
      message.error('Please input your email.');
      return;
    }

    if (!password) {
      message.error('Please input your password.');
      return;
    }

    authStore.login(username, password)
  }

  return (
    <div className='login'>
      <div className='login__main'>
        <div className='login__logo'>
          <img src={LogoWithText} width='420' />
        </div>

        <div className='login__form'>
          <div className='login__form--label'>Login to your account</div>
          <Input className='login__form--input' size='large' placeholder='E-mail' ref={usernameInput} />
          <Input className='login__form--input' size='large' type='password' placeholder='Password' ref={passwordInput} />
          <div className='login__form--btn' onClick={onLogin}>Login</div>
          <Link className='login__form--link' to='/forget-password'>Forgot password?</Link>
        </div>
      </div>
    </div>
  );
}

export default inject("authStore")(observer(Login));
