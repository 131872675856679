import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, Modal, Form } from 'antd';

const UserForm = (props) => {
  const { editUser, onCancel, onSubmit } = props;

  const [form] = Form.useForm();

  useEffect(() => {
    setTimeout(() => {
      form.resetFields();
    });
  }, [editUser]);

  const submitForm = () => {
    form.validateFields()
      .then((data) => {
        onSubmit(data);
      })
      .catch((err) => {
        console.info(err);
      });
  }

  return (
    <Modal
      title={editUser?._id ? 'Edit User' : 'Add User'}
      okText='Confirm'
      open={editUser}
      onCancel={onCancel}
      onOk={submitForm}
    >
      <Form
        form={form}
        layout='vertical'
        initialValues={editUser}
      >
        <Form.Item
          label='Email'
          name='email'
          rules={[
            { required: true, message: 'Email is required.' },
            { pattern: /^[\w\.-]+@[\w\.-]+\.\w+$/, message: 'Email does not match the format.' }
          ]}
        >
          <Input disabled={!!editUser?._id} />
        </Form.Item>

        {
          editUser?._id ? (
            null
          ) : (
            <Form.Item
              label='Password'
              name='password'
              rules={[
                { required: true, message: 'Password is required.' },
              ]}
            >
              <Input />
            </Form.Item>
          )
        }

        <Form.Item
          label='Name'
          name='name'
          rules={[{ required: true, message: 'Name is required.' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Company Name'
          name='companyName'
          rules={[{ required: true, message: 'Company name is required.' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Company Address'
          name='address'
          rules={[{ required: true, message: 'Company address is required.' }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}

UserForm.propTypes = {
  editUser: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default UserForm;
