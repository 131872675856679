import React from 'react';
import { Route, Redirect, Switch } from 'react-router';
import { inject, observer } from 'mobx-react';
import { Loading3QuartersOutlined } from '@ant-design/icons';

import PanelLayout from '../../layouts/PanelLayout';

import Login from '../Login';
import CFSMasterBLDetail from '../CFSMasterBLDetail';
import CFSMasterBLList from '../CFSMasterBLList';
import UserList from '../UserList';
import ResetPasswordByToken from '../ResetPassword/ResetPasswordByToken.js';
import ForgetPassword from '../ResetPassword/ForgetPassword.js';

import './index.less';

const Admin = (props) => {
  const { currentUser, isLoading } = props.authStore;

  if (!!isLoading) {
    return (
      <div className='cfs-loading'>
        <Loading3QuartersOutlined spin />
      </div>
    );
  }

  if (!currentUser) {
    return (
      <Switch>
        <Route path='/login'>
          <Login />
        </Route>

        <Route path='/forget-password'>
          <ForgetPassword />
        </Route>

        <Redirect to='/login' />
      </Switch>
    );
  }

  if (!!currentUser && !!currentUser.requireChangePass) {
    return (
      <Switch>
        <Route path='/change-password'>
          <ResetPasswordByToken />
        </Route>

        <Redirect to='/change-password' />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route path='/master-bl/:id'>
        <PanelLayout>
          <CFSMasterBLDetail />
        </PanelLayout>
      </Route>

      <Route path='/master-bl'>
        <PanelLayout>
          <CFSMasterBLList />
        </PanelLayout>
      </Route>

      {
        currentUser?.type == 'COMPANY_ADMIN' ? (
          <Route path='/users'>
            <PanelLayout>
              <UserList />
            </PanelLayout>
          </Route>
        ) : null
      }

      <Redirect to='/master-bl' />
    </Switch>
  );
}

export default inject('authStore')(observer(Admin));
