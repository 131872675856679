import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Form, Input, Select, DatePicker, Table, Dropdown, Menu, Popconfirm } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { SearchOutlined, MoreOutlined } from '@ant-design/icons';

import MasterBLForm from '../../components/MasterBLForm';

import './index.less';

const CFSMasterBLList = (props) => {
  const { findUser, addUser, fetchUsers } = props.userStore;
  const { currentUser } = props.authStore;
  const { fetchMasterBLs, setQueryParams, masterbls, queryParams, isLoading, clearQueryParams, addMasterBL, updateMasterBL } = props.masterBLStore;

  const [addingMBL, setAddingMBL] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchMasterBLs();
  }, []);

  const onCreate = () => {
    form.validateFields()
      .then((data) => {
        const formattedData = {
          ...data,
          houseBill: data.houseBill && data.houseBill.split('\n')
        };
        addMasterBL(formattedData, (newRecord) => props.routing.push(`/master-bl/${newRecord._id}`));
      })
      .catch((err) => {
        console.info(err);
      });
  }

  const menu = (record) => (
    <Menu>
      <Menu.Item key='view'>
        <Link to={`/master-bl/${record._id}`}>View</Link>
      </Menu.Item>

      {
          currentUser.type == 'COMPANY_ADMIN' ? (
            <Menu.Item key='cancel'>
              <Popconfirm
                title='Are you sure to delete it?'
                onConfirm={() => updateMasterBL({status: 'Cancelled', _id: record._id})}
              >
                Cancel
              </Popconfirm>
            </Menu.Item>
          ) : null
      }
    </Menu>
  );

  const columns = [
    {
      title: 'Master Bill',
      key: 'masterBill',
      dataIndex: 'masterBill',
      align: 'center',
      render: (content, record) => {
        return <Link to={`/master-bl/${record._id}`}>{ content }</Link>;
      }
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      align: 'center'
    },
    {
      title: 'Container Number',
      key: 'containerNumber',
      dataIndex: 'containerNumber',
      align: 'center'
    },
    {
      title: 'Eta',
      key: 'eta',
      dataIndex: 'eta',
      align: 'center',
      render: (content) => {
        return content && content > 0 ? moment(content).format('MM/DD/YYYY') : '-';
      }
    },
    {
      title: 'Outgated from Terminal',
      key: 'outgatedFromPier',
      dataIndex: 'outgatedFromPier',
      align: 'center',
      render: (content) => {
        return content && content > 0 ? moment(content).format('MM/DD/YYYY') : '-';
      }
    },
    {
      title: 'Available at RPG',
      key: 'availableAtWHSE',
      dataIndex: 'availableAtRPG',
      align: 'center',
      render: (content) => {
        return content && content > 0 ? moment(content).format('MM/DD/YYYY') : '-';
      }
    },
    {
      title: 'Last Free Day',
      key: 'freeTimeExpires',
      dataIndex: 'freeTimeExpires',
      align: 'center',
      render: (content) => {
        return content?.freeTimeExpires > 0 ? moment(content.freeTimeExpires).format('MM/DD/YYYY') : '-';
      }
    },
    {
      title: 'Customer',
      key: 'customer',
      align: 'center',
      render: (record) => {
        return record.user?.email;
      }
    },
    {
      title: 'Create Time',
      key: 'createdAt',
      dataIndex: 'createdAt',
      align: 'center',
      render: (content) => {
        return !!content ? moment(content).format('MM/DD/YYYY') : '-';
      }
    },
    {
      title: 'Action',
      index: 'action',
      align: 'center',
      render: (record) => {
        return (
          <Dropdown overlay={menu(record)}>
            <MoreOutlined style={{ fontSize: '18px', fontWeight: 900, cursor: 'pointer' }} />
          </Dropdown>
        );
      }
    },
  ];

  return (
    <div className='cfs-master-bl'>
      <div className='cfs-master-bl__topbar'>
        <div className='cfs-master-bl__topbar--left'>Master B/L</div>

        <div className='cfs-master-bl__topbar--right'>
          <div className='cfs-master-bl__topbar--btn'>Import</div>
          <div className='cfs-master-bl__topbar--btn add' onClick={() => setAddingMBL(true)}>Add</div>
        </div>
      </div>

      <div className='cfs-master-bl__search-bar'>
        <div className='cfs-master-bl__search-bar--cols'>
          <Input className='cfs-master-bl__search-bar--column' placeholder='Master B/L' suffix={<SearchOutlined style={{ color: '#CACACA' }} />} allowClear />

          <Select className='cfs-master-bl__search-bar--column' placeholder='Status' allowClear>
            <Select.Option key='Pending' value='Pending'>Pending</Select.Option>
            <Select.Option key='Processing' value='Processing'>Processing</Select.Option>
            <Select.Option key='Rejected' value='Rejected'>Rejected</Select.Option>
            <Select.Option key='PickedUp' value='PickedUp'>PickedUp</Select.Option>
            <Select.Option key='Completed' value='Completed'>Completed</Select.Option>
          </Select>

          <DatePicker.RangePicker allowClear />
        </div>

        <div className='cfs-master-bl__search-bar--btn'>Search</div>
      </div>

      <div className='cfs-master-bl__content'>
        <Table
          rowKey='_id'
          loading={isLoading}
          columns={columns}
          dataSource={masterbls.list}
          pagination={{
            pageSize: queryParams.pageSize,
            total: masterbls.count,
            current: queryParams.page,
            onChange: (page, pageSize) => setQueryParams('page', true)(page),
            showSizeChanger: false
          }}
        />
      </div>

      <MasterBLForm
        form={form}
        open={addingMBL}
        addUser={addUser}
        findUser={findUser}
        fetchUsers={fetchUsers}
        userRole={currentUser.type}
        onSubmit={onCreate}
        onCancel={() => setAddingMBL(false)}
      />
    </div>
  );
}

export default inject('routing', 'userStore', 'masterBLStore', 'authStore')(observer(CFSMasterBLList));
